import React from 'react';

type SiteSettingsProps = {

};

const SiteSettings: React.FC<SiteSettingsProps> = () => {

  return <div>TODO: Site Settings</div>
}
export default SiteSettings;